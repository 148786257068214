$shadow-mult: 1.5;


// transition function
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$animation-curve-default: $animation-curve-fast-out-slow-in !default;


// shadow settings
$shadow-key-umbra-opacity: 0.2*$shadow-mult !default;
$shadow-key-penumbra-opacity: 0.14*$shadow-mult !default;
$shadow-ambient-shadow-opacity: 0.12*$shadow-mult !default;

@mixin focus-shadow() {
  box-shadow: 0 0 8px rgba(0,0,0,.18),0 8px 16px rgba(0,0,0,.36);
}

@mixin shadow-0dp() {
  box-shadow: none;
}
@mixin shadow-1dp() {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 1px 1px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 1px 3px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-2dp() {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 2px 2px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 1px 5px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-3dp() {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 3px 4px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 1px 8px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-4dp() {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 4px 5px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 1px 10px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-5dp() {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 5px 8px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 1px 14px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-6dp() {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 6px 10px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 1px 18px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-7dp() {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 7px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 2px 16px 1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-8dp() {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 8px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-9dp() {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 9px 12px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 3px 16px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-10dp() {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 10px 14px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 4px 18px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-11dp() {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 11px 15px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 4px 20px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-12dp() {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 12px 17px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 5px 22px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-13dp() {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 13px 19px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 5px 24px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-14dp() {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 14px 21px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 5px 26px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-15dp() {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 15px 22px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 6px 28px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-16dp() {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 16px 24px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-17dp() {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 17px 26px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 6px 32px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-18dp() {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 18px 28px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 7px 34px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-19dp() {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 19px 29px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 7px 36px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-20dp() {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 20px 31px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 8px 38px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-21dp() {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 21px 33px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 8px 40px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-22dp() {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 22px 35px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 8px 42px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-23dp() {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 23px 36px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 9px 44px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-24dp() {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0px 24px 38px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0px 9px 46px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}


@mixin material-animation-fast-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation-curve-fast-out-slow-in;
}

@mixin material-animation-linear-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation-curve-linear-out-slow-in;
}

@mixin material-animation-fast-out-linear-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation-curve-fast-out-linear-in;
}

@mixin material-animation-default($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation-curve-default;
}


@mixin shadow() {
  box-shadow: 0 5px 5px rgba(#000, .3);
}

@mixin transition--all() {
  transition: all 0.4s ease;
}
