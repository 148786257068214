/*************************************

    SECTION PANELS

    = panels-wrapper
      ¬ slide
      ¬ --dark

    = panel-wd
      ¬ slide
      ¬ --right
      ¬ --dark

    = panel-board
      ¬ title
      ¬ main
      ¬ --right

    = hm-history
      ¬ carousel
      ¬ controls
      ¬ controls--mobile
      ¬ context

*************************************/


.panels-wrapper {
  position: relative;
  background-color: $bg-lighter;
  min-height: 640px;
  overflow: hidden;

  &--dark {
    background-color: $gray-dark;
  }

  .container,
  .row,
  .row > *,
  .panel-wd,
  &__slide,
  .panel-board {
    height: 100%;
  }
}

.panel-wd {
  position: absolute;
  left: 0;
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -25px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background-color: $bg-lighter;
    transform: rotate(45deg);
    z-index: 1;
  }

  &__slide {
    position: relative;
    min-height: 640px;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &--right {
    right: 0;
    left: auto;

    &:after {
      left: -25px;
      right: auto;
    }
  }

  &--dark {
    &:after {
      background-color: $gray-dark;
    }
  }

  @include media ('<sm') {
    min-height: auto;
    position: relative;

    &:after {
      display: none;
      top: auto;
      left: 50%;
      bottom: -25px;
      margin-left: -25px;
    }

    &--right {
      &:after {
        top: 0;
        bottom: auto;
      }
    }
  }

  &__slide {
    min-height: auto;

    img {
      position: static;
      transform: translate(0,0);
    }
  }
}


.panel-board {
  position: relative;
  z-index: 1;

  padding: 70/18 + em 35/555*100% 40/18 + em 0;
  transition: .4s;

  &__title {
    display: inline-block;
    margin-top: 0;
    padding-bottom: 13px;
    border-bottom: 1px solid $brand-warning;
  }

  &__main {
    margin-bottom: 50/18 + em;
  }

  &--right {
    padding: 70/18 + em 0 40/18 + em 35/555*100%;
  }

  @include media('<lg') {
    font-size: 1.63rem;
  }

  @include media('<sm') {
    width: 100%;
    text-align: center;
    padding: 80/18 + em 0;
  }
}


/**        ¬ hm-history-2         **/
.hm-history {
  &-carousel .panel-wd__slide img {
    width: auto;
  }

  &__controls {
    position: absolute;
    left: 7.5%;
    right: 7.5%;
    bottom: 7.5%;
    max-width: 850px;
    margin: 0 auto;
  }

  .panel-wd__slide {
    min-height: 640px;
  }

  @include media('>sm') {
    .panel-wd__slide img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  @include media('>1920px') {
    .panel-wd__slide img {
      width: 100%;
    }
  }

  @include media('<sm') {
    &-carousel .panel-wd__slide img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    .panel-wd {
      position: relative;
    }

    .panels-wrapper,
    .panel-wd__slide {
      min-height: 0;
    }
  }
}