/*************************************

    [TABLE OF CONTENTS]

    1.General settings:
      GENERAL
      TYPOGRAPHY
      CUSTOM GRID

    2.Pages, layouts:
      CUSTOM NAVBAR
      SECTION SETTINGS
      INTRO PAGES
      HOME PAGEs
      BLOG LIST, BLOG SINGLE POST
      SIDEBAR
      PAGE SERVICES, PAGE SERVICES-2
      PAGES "SERVICES DETAILS"
      GALLERIES
      ROOMs
      ROOM DETAILS
      ABOUT PAGE
      PAGE FOOTER

    3.Components & modifiers:
      Breadcrumbs for inner pages
      Custom buttons
      Transitions, animations, states
      Decorative elements
      Datepicker for Bootstrap
      CUSTOM FORM
      Contact elements
      Control carousel, pagination, tabs, video, lighbox
      SECTION PANELS
      Mobile menu Slideout.js

    Universal styles

*************************************/


// root path
$folder-path: './';

// Fonts
$fa-font-path: $folder-path + "fonts/" !default;

// Backgrounds
$bg-path: $folder-path + 'images/';

// Sprites
@mixin sprite-image($sprite) {
  background-image: url(#{$sprite-image});
}
$sprite-image: $folder-path + "images/sprite.png";