/*************************************

    Transitions, animations, states

    = logo
    = link
    = link-more

*************************************/

a,
h1,h2,h3,h4,h5,h6,
.btn,
.btn-play,
.control-panel__nav,
.logo,
.navbar__header,
.navbar__brand,
.form-control,
.label-control,
.flag,
.flag::before,
.icon-back {
  @include transition--all();
}


a {
  &:hover,
  &:hover .icon-back {
    color: $link-hover-color;
  }

  &:focus {
    color: $gray-base;
    outline: none;
  }

  &:active .icon-back {
    color: $headings-color;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.hightlight {
  color: $link-hover-color;
}

.hightgray {
  color: $gray-base;
}

.link,
.link-more {
  color: $brand-warning;
  box-shadow: 0 1px $brand-warning;

  &:hover {
    color: $link-hover-color;
    box-shadow: 0 1px $link-hover-color;
    border-color: $link-hover-color;
  }
  &:focus {
    color: $gray-base;
    box-shadow: 0 1px $gray-base;
    border-color: $gray-base;
  }

  &--warn-bg {
    color: #fff;
    background-color: $brand-warning;

    &:hover,
    &:focus {
      color: #fff;
      background-color: $link-hover-color;
      box-shadow: 0 0 $link-hover-color;
    }
  }
}

.navbar-brand .logo {
  &:hover, &:focus, &:active {
    fill: $link-hover-color;
    color: $link-hover-color;
    opacity: .4;
  }
}


// Overlay thumbnail
.instagram-top-list a,
.sidebar-thumb {
  &:after {
    position: relative;
    display: block;
    content: '';
    height: 100%;
    background-color: rgba($link-hover-color, 0);
    @include transition--all();
  }
}


.sidebar-preview__content {
  &:hover .sidebar-thumb:after,
  &:focus .sidebar-thumb:after {
    background-color: rgba($link-hover-color, .7);
  }
}

.instagram-top-list a {
  &:hover:after,
  &:focus:after {
    background-color: rgba($link-hover-color, .7);
  }
}


%hover-border {
  position: relative;
  line-height: 48/18 + em;
  @include transition--all();

  &:after {
    position: absolute;
    content: ' ';
    left: 0;
    bottom: 5px;
    height: 0;
    width: 96.6%;
    opacity: 0;
    transform: translateY(0);
    background-color: $brand-warning;
    @include transition--all();
  }

  &.active, &:focus {
    color: $brand-warning;

    &:after {
      height: 2px;
      opacity: 1;
      transform: translateY(5px);
    }
  }
}