/*************************************

    Control carousel, pagination, tabs, video, lighbox

    Carousel
    = control-panel
      ¬ nav
      ¬ prev
      ¬ next
      ¬ tabs
      ¬ dots
      ¬ filter
      ¬ mobile

    Video:
    = elemets for video
      ¬ btn-play
    = video-contain

    Lightbox:
    = blueimp-gallery-controls
      ¬ prev
      ¬ next
      ¬ close
      ¬ indicator

*************************************/

.owl-carousel .owl-item {
  transform: translateZ(0);
 -webkit-transform: translateZ(0);
 -ms-transform: translateZ(0);
}

/**        = dots and tabs, navigation arrows         **/
.control-panel {
  position: relative;
  padding: 0 50px;
  z-index: 10;

  /**        ¬ arrows         **/
  &__nav {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 48px;
    background-color: $gray-dark;
    border: 2px solid $gray-dark;
    border-radius: $border-radius-base;
    color: $brand-warning;
    transform: translateY(-50%);
    z-index: 10;

    .fa {
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
      vertical-align: middle;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      border-color: $gray-base;
      color: $gray-base;
    }
  }

  .prev {
    left: 0;
    background-image: url('');
  }

  .next {
    right: 0;
    background-image: url('');
  }

  &__tabs {
    text-align: center;
    margin-left: -50px;

    > * {
      display: inline-block;
      margin-left: 50px;
      font-family: $lato;
      font-size: 1em;
      color: $gray-light;
      letter-spacing: $ls;
      text-transform: uppercase;
      cursor: pointer;
      @extend %hover-border;
    }
  }


  &__dots {
    line-height: 1;
    padding: 15px 0;
    text-align: center;

    .owl-dot,
    .swiper-pagination-bullet {
      display: inline-block;
      font-size: 18px;
      width: auto;
      height: auto;
      padding: 0 7/18 + em;
      vertical-align: middle;
      cursor: pointer;
    }

    .swiper-pagination-bullet {
      background-color: transparent;
      border-radius: 0;
      opacity: 1;

      &:after {
        content: '';
      }
    }

    .owl-dot > span,
    .swiper-pagination-bullet:after {
      position: relative;
      display: block;
      width: 12/18 + em;
      height: 12/18 + em;
      top: 50%;
      margin: 0 auto;
      margin-top: -6/18 + em;
      transform: rotate(45deg);
      background-color: #fff;
      @include transition__all();
    }

    .owl-dot > span:hover,
    .swiper-pagination-bullet:hover:after {
      background-color: #ccc;
    }

    .owl-dot.active > span,
    .swiper-pagination-bullet-active:after {
      background-color: $brand-warning;
    }
  }


  @include media('<md') {
    &--filter {
      min-height: 30px;
      padding: 0 30px;

      .prev,
      .next {
        font-size: 30px;
      }
    }

    &__tabs {
      margin-left: -30px;
    }

    &__tabs > * {
      line-height: 30px;
      margin-left: 30px;
    }
  }

  @include media('<sm') {
    &--mobile {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
    }

    &__tabs {
      margin-left: -5%;
      font-size: 13px;

      > * {
        margin-left: 5%;

        @include media('<380px') {
          letter-spacing: $ls-xs;
        }
      }
    }
  }
}


/**        = elemets for video         **/
.btn-play {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $gray-dark;

  cursor: pointer;
  z-index: 5;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -10px;
    @include triangle(right, $brand-warning, 3rem, 4.4rem);
    @include transition--all();
  }

  &:hover,
  &:focus {
    background-color: #ccc;
    &:after {
      @include triangle(right, #000, 3rem, 4.4rem);
    }
  }

  &:active {
    background-color: #fafafa;
    &:after {
      @include triangle(right, #888, 3rem, 4.4rem);
    }
  }
}


/**        = video         **/
.video-contain {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  border-top-right-radius: 3px;
  border: 1px solid transparent;

  &__preview {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  .video-controls {
    left: 0;
    top: 0;
    width: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  &__preview {
    background-size: cover;
    background-repeat: no-repeat;
    @include transition--all();
  }

  .btn-play {
    /**         width 80px         **/
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    visibility: visible;
    opacity: 1;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    embed,
    object,
    iframe {
      @extend .video-contain__wrapper;
    }
  }


  /**        ¬ helper classess         **/
  &__preview {
    &.hover {
      border-color: rgba(#f8f8f8, 1);

      .overlay {
        background-color: rgba(#fff, .85);
      }

      .flag {
        opacity: .7;
      }
    }
  }

  &.actived {
    .video-contain__preview,
    .btn-play {
      visibility: hidden;
      opacity: 0;
    }
  }
}


/**        = Lightbox         **/
.blueimp-gallery-controls {
  .prev,
  .next {
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    transition: all .4s;
    border-width: 1px;
    border-color: transparent transparent #fff #fff;
    background-color: transparent;
    border-radius: 0;
  }

  .prev {
    left: 40px;
  }

  .next {
    right: 40px;
    border-color: #fff #fff transparent transparent;
  }

  .close {
    top:40px;
    right: 40px;
    font-size: 34px;
    width: 1em;
    height: 1em;
    transform: rotate(45deg);

    &:before,
    &:after {
      left: 0;
      top: 0;
      position: absolute;
      content: '';
      background-color: #fff;
    }

    &:before {
      top: 50%;
      width: 1em;
      height: 2px;
      margin-top: -1px;
    }

    &:after {
      left: 50%;
      width: 2px;
      height: 1em;
      margin-left: -1px;
    }
  }

  > .indicator {

    > li {
      position: relative;
      background-size: cover;
      transition: all .4s;
      z-index: 1;
      border: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 50%;
        background-color: rgba(#fff, .7);
        transition: all .4s;
      }

      &.active:after {
        opacity: 1;
      }
    }
  }
}