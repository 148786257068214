/*************************************

    PAGES "SERVICES DETAILS"

*************************************/

.services-details-intro {
  &__slider {
    .swiper-slide {
      height: 80vh;
    }
  }

  &__panel {
    position: relative;
    padding: 10px;
    margin: 0 -15px 40px;
    background-color: $gray-base-light;
    z-index: 1;

    h1 {
      color: #ffffff;
      margin: 14px 0 45px;
    }

    @include media('>sm') {
      width: 100%;
      max-width: 550px;
      margin: -20% 0 100px auto;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    color: $gray-light;
    letter-spacing: 200/1000 + em;
    text-transform: uppercase;
    font-family: $lato;

    .cost {
      color: $brand-warning;
    }
  }

  &__panel-inner {
    padding: 50px 30px;
    border: 2px solid rgba($gray-light, 0.30);
  }
}

.services-details-intro-two {
  .hr-heading {
    margin: 160px 0;
  }

  @include media('<sm') {
    .hr-heading {
      margin-bottom: 80px;
    }
  }
}

.services-details-content {
  margin-bottom: 80px;

  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: inline-block;
    }
  }
}

.services-details-categories {
  margin-bottom: -80px;

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    color: #ffffff;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($gray-base, 0.60);
    }

    .h2 {
      position: relative;
      margin: 0;
      color: #ffffff;
      z-index: 1;
      visibility: hidden;
    }
  }

  &__nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-around;
    z-index: 2;
  }

  &__arrow {
    display: flex;
    align-items: center;
    color: #ffffff;
    line-height: 1;
    font-size: 18px;

    .fa {
      padding: 0 15px;
      font-size: 40/18 + em;
      color: $brand-warning;
    }

    .h2 {
      margin: 0;
      color: inherit;
    }

    &:hover {
      color: $brand-warning;
    }
  }

  @include media('<sm') {
    &__link {
      .h2 {
        visibility: visible;
      }
    }

    &__nav {
      justify-content: space-between;
    }

    &__arrow {
      .h2 {
        display: none;
      }
    }
  }
}

.services-details-carousel {
  position: relative;
  height: 85vh;

  .control-panel {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 50px;
    z-index: 1;

    &__nav {
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
      }
    }

    &__dots {
      order: 1;
    }
  }
}

.services-details-book {
  padding: 80px 15px;
}

.services-details-booking {
  .btn {
    margin-top: 50px;
  }
}
