/*************************************

    SECTION SETTINGS

    = st-row
      ¬ inner
      ¬ section-title
      ¬ heading
      ¬ st-row--hr
      ¬ st-row--gray
      ¬ st-row--bg

    = hr-heading
      ¬ h1
      ¬ small

    = over
    = overlay
    = full-wd

*************************************/

.st-row {
  position: relative;
  padding-bottom: 50/18 + em;

  h2.section-title {
    margin: 38/30 + em 0;
  }

  @include media('<md') {

    &__heading {
      margin-bottom: 30/18 + em;
    }

    &__inner {
      h1 {
        line-height: 1em;
        margin-bottom: 15/30 + em;
      }
    }
  }

  @include media('<xs') {
    &__inner {
      h1 {
        font-size: 24/18 + em;
        line-height: 30/24 + em;
        margin-bottom: 15/24 + em;
      }
    }
  }

  &--gray {
    background-color: $bg-lighter;
  }

  &--bg {
    background: url($bg-path + 'bg-section.png') repeat 0;
  }

  /**        ¬ section no title - add padding-top          **/
  &--hr {
    padding: 80/18 + em 0;
  }
}


 /**        ¬ hero section title         **/
.hr-heading {
  position: relative;
  text-align: center;
  margin-top: 75/18 + em;
  margin-bottom: 50/18 + em;

  h1, .h1 {
    position: relative;
    text-align: center;
    font-size: 48/18 + em;
    line-height: 1;
    padding-bottom: 30/48 + em;
    margin-top: 0;
    margin-bottom: 30/48 + em;
    font-weight: 700;
    border-bottom: 1px solid $brand-warning;

    &:after {
      display: block;
      position: absolute;
      content: ' ';
      left: 50%;
      bottom: -5px;
      font-size: 9px;
      height: 9px;
      width: 9px;
      background-color: $brand-warning;
      transform: translateX(-50%);
    }
  }

  &.rotate h1:after, .h1:after {
    transform: translateX(-50%) rotate(45deg);
  }

  small {
    display: inline-block;
    width: 100%;
    font-family: $lato;
    font-size: 1em;
    color: $gray-light;
    letter-spacing: $ls;
    text-transform: uppercase;
  }

  @include media('<md') {
    margin-bottom: 30/18 + em;
  }
}


/**        = overlay         **/
.over {
  background-color: rgba(#000, .7);

  &--light {
    background-color: rgba(#fff, .6);
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000, .7);
  @include transition--all();

  &--light {
    background-color: rgba(#fff, .6);
  }
}