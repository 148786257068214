/*************************************

    INTRO PAGES

    = page-home
      ¬ extra-title
      ¬ intro-banner

    = page-inner
      ¬ intro-banner

    = intro-slider
      ¬ swiper-slider
      ¬ intro-hero
        ¬ price

      ¬ promo-title
      ¬ intro-title
      ¬ intro-title--decor
      ¬ list-type
      ¬ control-panel

    = PAGE 3 intro-video

    SETTINGS background and parallax
    = intro-bg

*************************************/

.page-home .intro-banner {
  text-align: center;

  .intro-video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__inner {
    height: 720px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, .6);
  }

  @include media ('<sm') {
    &__inner {
      padding-top: 78px;
      height: 115vh;
    }
  }

  @include media ('<590px') {
    &__inner {
      height: 100vh
    }
  }
}

// Big title for intro section
.extra-title {
  flex-basis: 100%;
  color: #fff;

  &__top {
    font-family: $lato;
    color: $brand-warning;
    text-transform: uppercase;
    letter-spacing: $ls;
  }

  h1 {
    font-size: 92/18 + em;
    margin: 5/92 + em 0 30/92 + em;
    color: inherit;
    white-space: nowrap;
  }

  span.small {
    display: inline-block;
    font-size: 30/18 + em;
    line-height: 36/30 + em;
    margin-top: 47/30 + em;
  }

  @include media('<sm') {
    max-width: 320px;

    h1 {
      font-size: 64px;
    }
  }

  @include media('<320px') {
    max-width: 280px;

    h1 {
      font-size: 56/18 + em;
    }

    span.small {
      font-size: 24/18 + em;
      line-height: 36/24 + em;
      margin-top: 47/24 + em;
    }
  }
}

// for inner page
.page-inner .intro-banner {
  text-align: center;

  &__inner {
    background-color: rgba(#000, .7);
    padding: 138/18 + em 0 113/18 + em;

    @include media('<sm') {
      padding: 216/18 + em 0 113/18 + em;
    }
  }

  .title {
    color: #fff;
    font-size: 48/18 + em;
    margin: 5/48 + em 0 30/48 + em;
  }
}


.intro-banner {

  &__inner {
    position: relative;
  }
}


/**        PAGE 2 = intro-slider         **/
.intro-slider {
  height: 720px;
  min-height: 590px;

  .swiper-slide {
    position: relative;
    background-position: 50% 25%;
    background-size: cover;

    &__inner {
      position: relative;
      height: 100%;
    }

    &:after {
      position: absolute;
      content: ' ';
      display: block;
      background-color: rgba(#000, .7);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  .intro-hero {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    color: #fff;
    z-index: 2;

    &__price {
      margin-bottom: 20px;

      > *:first-child {
        margin-right: 10px;
      }
    }

    p, .list-type {
      margin-bottom: 13px;
    }

    p {
      max-width: 255px;
    }

    &--center {
      max-width: 557px;
      flex-basis: 557px;
      margin: 0 auto;
      text-align: center;

      p {
        max-width: none;
      }
    }

    .promo-title {
      @extend %text-brand;
    }

    .intro-title {
      position: relative;
      color: #fff;
      margin-top: 0;
      margin-bottom: 30/48 + em;

      &__text {
        display: inline-block;
        margin-bottom: 13/48 + em;

        &.under {
          border-bottom: 1px solid $brand-warning;
          padding-bottom: 13/48 + em;
          margin-bottom: 0;
        }
      }

      .promo-title {
        display: block;
        font-size: 18/48*100%;
        line-height: 1;
        font-weight: 400;
        margin-bottom: 13/18 + em;
      }
    }

    .intro-title--decor {
      padding-bottom: 25/48 + em;
      margin-bottom: 25/48 + em;
      border-bottom: 1px solid $brand-warning;

      &:after {
        @extend %bullet-rotate;
        position: absolute;
        left: 50%;
        bottom: -5px;
        font-size: 10px;
        margin-left: -5px;
      }
    }

    .flag {
      margin-left: 30px;
      vertical-align: middle;
    }

    .list-type {
      ul > li {
        margin-bottom: 7px;
      }
    }

    .cost {
      @extend %text-brand-lg;
      letter-spacing: normal;
    }

    .cost-disable {
      @extend %text-brand;
      color: $gray-light;
      letter-spacing: normal;
      text-decoration: line-through;
    }

    .btn {
      margin-top: 30/14 + em;
    }
  }

  .control-panel {
    &__wrapper {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      z-index: 2;
    }

    &__nav {
      transform: translateY(0);
    }

    &__dots {
      position: static;
    }
  }

  @include media('<sm') {
    height: 100vh;
  }

  @include media ('<xs') {
    .intro-hero {
      text-align: center;
      p {
        margin: 0 auto;
      }

      .flag {
        display: block;
        margin: 15px auto 0;
      }
    }
  }
}

/**        settings background and parallax effect         **/
.intro-bg {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 1;
}
